import { Slider, Typography } from "@mui/material"

// Floodplains, heavy trees, underbrush: 0.150
export const ManningSlider = ({ value, onChange }) => {

    let marks = [
        
            { label: "Earth, straight, uniform", minValue: 0.018, maxValue: 0.025 },
            { label: "Earth, winding, sluggish", minValue: 0.025, maxValue: 0.035 },
            { label: "Natural channels, clean, straight", minValue: 0.025, maxValue: 0.040 },
            { label: "Natural channels, sluggish with pools", minValue: 0.035, maxValue: 0.050 },
            { label: "Floodplains, dense brush", minValue: 0.070, maxValue: 0.160 },
            { label: "Floodplains, heavy trees, underbrush", minValue: 0.100, maxValue: 0.200 },
            { label: "Concrete, trowel finish", minValue: 0.012, maxValue: 0.014 },
            { label: "Concrete, unfinished", minValue: 0.014, maxValue: 0.017 },
            { label: "Asphalt", minValue: 0.013, maxValue: 0.015 },
            { label: "Brickwork", minValue: 0.012, maxValue: 0.015 },
            { label: "Cast iron pipes", minValue: 0.010, maxValue: 0.015 },
            { label: "Corrugated metal pipes", minValue: 0.022, maxValue: 0.027 },
            { label: "Short grass, clipped", minValue: 0.025, maxValue: 0.035 },
            { label: "Medium grass", minValue: 0.030, maxValue: 0.050 },
            { label: "Tall grass, uncut", minValue: 0.035, maxValue: 0.050 },
            { label: "Dense weeds, high grass", minValue: 0.035, maxValue: 0.070 },
            { label: "Rock, gravelly bed", minValue: 0.025, maxValue: 0.035 },
            { label: "Rocky, mountain streams", minValue: 0.030, maxValue: 0.060 },
            { label: "Coarse gravel, uniform size", minValue: 0.024, maxValue: 0.028 },
            { label: "Fine gravel", minValue: 0.022, maxValue: 0.025 },
            { label: "Light brush, no trees", minValue: 0.035, maxValue: 0.050 },
            { label: "Medium to heavy brush", minValue: 0.050, maxValue: 0.080 },
            { label: "Debris-filled channel", minValue: 0.050, maxValue: 0.150 },
            { label: "Slow water", minValue: 0.2, maxValue: 5}         
    ]
       
    marks = marks.map((mark) => ({...mark, value: mark.minValue}))

    const items = marks.filter(mark => (value >= mark.minValue && value <= mark.maxValue)).sort((a, b) => a.minValue - b.minValue)
    //get teh first item in the array
    const item = (items.length)?items[0]:null

    return (
        <>
        
        <Slider
            value={value}
            onChange={onChange}
            valueLabelDisplay="auto"
            step={0.01}
            marks={false}
            min={0.01}
            max={5}
        />
        <Typography>
           {item?.label} [{item?.minValue},{item?.maxValue}]
        </Typography>
        
        </>
    )

}//end ManningSlider