import React, { useState } from "react";
import {Box, Grid} from "@mui/material"
import SaferplacesAppbar from "./SaferplacesAppbar";
import Footer from "./Footer";
import { ToolsContext} from "./ToolManager";
import { Tools } from "@SaferPlaces2023/safer-map";

const AppWrapper = ({children, toolbarType, simple}) => {

    const [activeTool, setActiveTool] = useState(Tools.PAN);
    
    return (
        <>
          <ToolsContext.Provider value={[activeTool, setActiveTool]}>
            
            <Grid container
              direction="column"
                style={{
                  height: "100%",
                  flexWrap: "nowrap",
                flexDirection:"column"}}
              >

                <Grid container 
                // style={{height:'95%', paddingTop: '95px'}}
                  className="grid-toolbar-container">
                  <SaferplacesAppbar 
                    toolbarType ={toolbarType} simple={simple}
                    className="safer-toolbar"
                  />
                </Grid>
                
                <Box width="100%" height="100%" alignItems="center"
                className="safer-content">
                  {children}
                </Box>
                
                <Grid container item >
                  <Footer />
                </Grid>

            </Grid>
          </ToolsContext.Provider>
        </>
    )
}

export default AppWrapper;