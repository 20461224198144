/**
 * To add a new closed option, add a new key to the options object, 
 * with the name of the option as key and the array of possible values as value. 
*/
export const closedOptions = {
    currency: ["$", "€", "£"],
}

// These options are set once and never changed. and their value does not depend by the user. 
export const readOnlyOptions = ["hello_option"]

// These options are set once and never changed. The user choose the value when the project is created
export const options = {
    //enable_damage_preview: false,
    is_coastal: true, // l'opzione is coastal non dipende dall'utente ma dal DEM
}

export const defaultSettings = [
    ...Object.keys(closedOptions).map(key => [key, closedOptions[key][0]]),     // set the first element of each list as default value
    ...Object.keys(options).map(key => [key, options[key]])                     
]