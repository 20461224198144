//export const __drive__ = "/mnt/s3/saferplaces.co"
export const __drive__ = "/mnt/efs"

export const getToken = () => sessionStorage.getItem("token")

export const getUserName = () => sessionStorage.getItem("username")

export const getProjectName = () => sessionStorage.getItem("projectname")

export const developers = [
    "valluzzi@gmail.com",
    "valerio.luzzi@gecosistema.com",
    "marco.renzi@gecosistema.com",
    "debora.cocchi@gecosistema.com"
]

export const MAX_AREA = 40000**2 // 1600 km^2

export const RER_BASINS = "Bacini_DQ60_PdG_21_E32_v4"
export const RER_RIVERS = "Fiumi per i quali viene definita la criticità idraulica"