import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {Drawer, AppBar, Toolbar, Grid, Typography, Box, List, Divider, IconButton} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material/';
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Paths from '../utils/paths';
import { fireEvent, preventDefault } from '../utils/events';
import { NewProjectTools } from './NewProjectTools';
import saferplaces_logo from "../images/Safer Places - Logo.png";
import { isEntraID, logout } from '../utils/http';
import {Header} from './Header';
import UserProfileDialog from '../dialog/UserProfileDialog';
import SettingsDialog from '../dialog/settings/SettingsDialog';
import ResourcesLabel from './fields/ResourcesLabel';
import CloseIcon from '@mui/icons-material/Close';
import MemoryIcon from '@mui/icons-material/Memory';
import ProjectToolbar from './ProjectToolbar';
import { getUserName } from '../utils/const';
import {version} from '../utils/version';
import { useMsal } from '@azure/msal-react';


// -------------- APPBAR and DRAWER --------------

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


export default function SaferplacesAppbar({toolbarType, simple}) {

  // default props
  const { instance } = useMsal();
  const navigate = useNavigate()
  const username = getUserName()
  const [logged, setLogged] = useState(!!username)
  
  const [open, setOpen] = useState(false);
 
  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleOpenSettings = () => {
    fireEvent("dialog:settings")
  }

  const handleUserProfile = () => {
    fireEvent("dialog:userProfile")
  }

  /* as soon the user logs out, return to the login page */ 
  useEffect(() => {
    if (!logged) navigate(Paths.LOGIN) 
  }, [logged]) //eslint-disable-line

  const handleLogout = () => {

    if (isEntraID(username)){
      
      instance.logoutRedirect({
        postLogoutRedirectUri: Paths.LOGIN
      }).then(() => {
        logout()
        setLogged(false)
      })
    }else{
      logout()
      setLogged(false)
    }
    
  }
  
  
  const showTools = () => {
    
    switch (toolbarType) {
      case "newProject":
        return <NewProjectTools />
      case "editProject":
        // return <EditProjectTools />
        return <ProjectToolbar simple={simple}/>
      default:
        return <></>
    }
      
  }

  return (    
    <>
          <UserProfileDialog />
          <SettingsDialog />

          <Grid container direction="column">
            
            <Grid item className='safer-grid-item'>
              <Box onContextMenu={preventDefault} sx={{ display: 'flex'}}>
                  <CssBaseline />
                  
                  <AppBar open={open}
                  color="secondary"
                  className="safer-appbar">
                    
                    <Header className="safer-header"/>

                    <Toolbar className="safer-toolbar">

                      <Grid container justifyContent="start" columnSpacing={2} sx={{display:"flex",height:"100%",alignItems:"center"}}>
                          <Grid item >
                            <img src={saferplaces_logo} alt="saferplaces logo" height="50" 
                            style={{ 
                              padding: 5, display:'block'
                             }} />
                          </Grid>
                          <Grid item className={`safer-tools ${toolbarType === "newProject" ? "safer-newProj" : "safer-editProj"}`}>
                            { showTools() }
                          </Grid>
                      </Grid>

                      <Grid item className='safer-hamburger'>
                        <Typography style={{color:"#aaaaaa"}}
                        className='safer-version'>{`v${version}`}</Typography>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={openDrawer}
                            
                            >
                            <MenuIcon />
                        </IconButton>
                      </Grid>
                     

                    </Toolbar>
                  </AppBar>                  
                </Box>
              </Grid>

            </Grid>


            <Drawer open={open} anchor="right" width={100} >
                
                <DrawerHeader sx={{paddingTop:"50px"}}>
                    <Typography>{username ? username : ""}</Typography>
                    <IconButton onClick={closeDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>
                
                <Box sx={{marginTop:"0px"}}>

                  <List>

                      <ListItem key="menuProfile" disablePadding sx={{ display: 'block' }}>
                          <ListItemButton sx={{minHeight: 48,justifyContent: open ? 'initial' : 'center',px: 2.5,}}
                              onClick={handleUserProfile}>
                              <ListItemIcon sx={{minWidth: 0,mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                                <AccountCircle />
                              </ListItemIcon>
                              <ListItemText primary={"PROFILE"} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                      </ListItem>

                      <ListItem key="menuSettings" disablePadding sx={{ display: 'block' }}>
                          <ListItemButton sx={{minHeight: 48,justifyContent: open ? 'initial' : 'center',px: 2.5,}} 
                              onClick={handleOpenSettings}>
                                                        
                              <ListItemIcon sx={{minWidth: 0,mr: open ? 3 : 'auto',justifyContent: 'center'}}>
                                  <SettingsIcon />
                              </ListItemIcon>
                              <ListItemText primary="SETTINGS" sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                      </ListItem>
                      
                      <ListItem key="CpuRam" disablePadding sx={{ display: 'block' }}>
                          <ListItemButton sx={{minHeight: 48,justifyContent: open ? 'initial' : 'center',px: 2.5,}}
                              onClick={null}>
                              <ListItemIcon sx={{minWidth: 0,mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                                <MemoryIcon />
                              </ListItemIcon>
                              <ListItemText primary={<ResourcesLabel />} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                      </ListItem>

                      {/* - - - - - - - - - - - - - -  */}
                      <Divider/> 
                      
                      <ListItem key="menuLogout" disablePadding sx={{ display: 'block' }}
                                onClick={handleLogout}>
                        <ListItemButton sx={{minHeight: 48,justifyContent: open ? 'initial' : 'center',px: 2.5,}}>
                            <ListItemIcon sx={{minWidth: 0,mr: open ? 3 : 'auto',justifyContent: 'center'}}>
                                  <LogoutIcon />
                              </ListItemIcon>
                            <ListItemText primary="LOGOUT" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>
                  </List>

                </Box>
                
              <Divider />
            </Drawer>


            </>
  );
  
}
